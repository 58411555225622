import { useContext } from "react";
import { EmailContext } from "~context/EmailContext/index.jsx";

export const useEmail = () => {
  const email = useContext(EmailContext);

  return email;
};

export default useEmail;
