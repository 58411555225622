import { useContext } from "react";
import { ThemeContext } from "~context/ThemeContext/index.jsx";

export const useTheme = () => {
  const theme = useContext(ThemeContext);

  return theme;
};

export default useTheme;
