/* eslint-disable import/prefer-default-export */
/* eslint-disable react/no-danger */

export const sendNotification = (detail) => {
  if (typeof window !== `undefined`) {
    const notificationEvent = new CustomEvent(`notification_push`, {
      detail
    });

    dispatchEvent(notificationEvent);
  }
};
