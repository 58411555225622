/* eslint-disable camelcase */
import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useCookies } from "react-cookie";
import { globalHistory } from "@reach/router";
import { useAuth, useTimeout } from "~hooks";

export const AppContext = createContext({});

const NOTIFICATION_TIMEOUT = 5000;

const AppProvider = ({ children }) => {
  // ===========================================================================
  // context / ref / state

  const { account } = useAuth();
  const [cookies, setCookie] = useCookies(null);

  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  const [cookieMessageActive, setCookieMessageActive] = useState(false);
  const [loadingActive, setLoadingActive] = useState(false);
  const [loginActive, setLoginActive] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [newsletterActive, setNewsletterActive] = useState(false);
  const [notification, setNotification] = useState(null);
  const [registerActive, setRegisterActive] = useState(false);
  const [pathname, setPathname] = useState(null);

  // ===========================================================================
  // lifecycle

  useEffect(() => {
    if (typeof window !== `undefined` && window?.location?.pathname) {
      setPathname(window.location.pathname);

      window.addEventListener(`notification_push`, (e) => {
        setNotification(e.detail);
      });
    }

    if (
      typeof window !== `undefined` &&
      !loginActive &&
      !registerActive &&
      !menuActive &&
      !cookies?.newsletter_seen
    ) {
      setTimeout(() => {
        setCookie(`newsletter_seen`, true, { path: `/` });
        setNewsletterActive(true);
      }, 3000);
    }

    return globalHistory.listen(({ location }) => {
      setPathname(location.pathname);
    });
  }, []);

  useEffect(() => {
    if (cookiesAccepted) {
      setCookie(`accepted`, true, { path: `/` });
    }
  }, [cookiesAccepted]);

  useEffect(() => {
    if (!notification) {
      return () => {};
    }

    const notificationTimeout = setTimeout(() => {
      setNotification(null);
    }, NOTIFICATION_TIMEOUT);

    return () => {
      clearTimeout(notificationTimeout);
    };
  }, [notification]);

  useEffect(() => {
    if (typeof account?.tokens !== `undefined`) {
      setNotification(`Your token balance is now ${account?.tokens}.`);
    }
  }, [account?.tokens]);

  // ===========================================================================
  // render

  return (
    <AppContext.Provider
      value={{
        cookiesAccepted,
        setCookiesAccepted,
        cookieMessageActive,
        setCookieMessageActive,
        loadingActive,
        setLoadingActive,
        loginActive,
        setLoginActive,
        menuActive,
        setMenuActive,
        newsletterActive,
        setNewsletterActive,
        notification,
        setNotification,
        registerActive,
        setRegisterActive,
        pathname
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppProvider;
