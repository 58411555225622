import { useEffect, useState } from "react";

const useWindowDimensions = () => {
  const getWindowDimensions = () => {
    if (typeof window === `undefined`) {
      return {};
    }

    const { innerWidth: width, innerHeight: height } = window;

    return {
      width,
      height
    };
  };

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener(`resize`, handleResize);

    return () => window.removeEventListener(`resize`, handleResize);
  }, []);

  return windowDimensions;
};

export default useWindowDimensions;
