/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { createContext, useEffect, useState } from "react";
import firebase from "gatsby-plugin-firebase";
import { sendNotification } from "~utils/notifications";

export const AuthContext = createContext({});

const BROWSER = typeof window !== `undefined`;

const AuthProvider = ({ children }) => {
  // ==========================================================================
  // state

  const [account, setAccount] = useState(null);
  const [authUser, setAuthUser] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  // ==========================================================================
  // methods

  //
  // helpers

  const resetData = () => {
    setAccount(null);
    setAuthUser(null);
    setLoggedIn(false);
  };

  const getAccountDocumentById = async (id) => {
    const documentRef = await firebase.firestore().doc(`/accounts/${id}`);

    return documentRef;
  };

  //
  // session

  const login = async ({ email, password }) => {
    let result = true;

    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);

      sendNotification(`You are now logged in.`);
    } catch (e) {
      result = e?.message;
    }

    return result;
  };

  const logout = async () => {
    resetData();

    await firebase.auth().signOut();

    sendNotification(`You are now logged out.`);

    return true;
  };

  //
  // account

  const resetUserPassword = async () => {
    if (!authUser?.email) {
      return;
    }

    await firebase.auth().sendPasswordResetEmail(authUser.email);

    sendNotification(`Password reset email sent.`);
  };

  const resetEmailPassword = async (email) => {
    if (!email) {
      return;
    }

    let result = true;

    try {
      await firebase.auth().sendPasswordResetEmail(email);

      sendNotification(`Password reset email sent to ${email}`);
    } catch (e) {
      result = e?.message;
    }

    return result;
  };

  const loadAccountById = async (id) => {
    const documentRef = await getAccountDocumentById(id);

    if (typeof documentRef?.get === `function`) {
      const freshAccount = await documentRef.get();
      const freshAccountData = freshAccount.data();

      setAccount({
        id: freshAccount.id,
        ...freshAccountData
      });
    } else {
      // eslint-disable-next-line no-console
      console.error(`Could not refresh account '${id}'`);
    }
  };

  const refreshCurrentAccount = async () => {
    const documentRef = await getAccountDocumentById(account.id);

    if (typeof documentRef?.get === `function`) {
      const freshAccount = await documentRef.get();
      const freshAccountData = freshAccount.data();

      sendNotification(`Account info refreshed.`);

      setAccount({
        id: freshAccount.id,
        ...freshAccountData
      });
    } else {
      // eslint-disable-next-line no-console
      console.error(`Could not refresh account '${account.id}'`);
    }
  };

  const updateAccountById = async (id, data, notify = true) => {
    const documentRef = await getAccountDocumentById(id);

    if (typeof documentRef?.update === `function`) {
      await documentRef.update(data);

      if (notify) {
        sendNotification(`Account info updated.`);
      }
    } else {
      // eslint-disable-next-line no-console
      console.error(`Cannot update data for ID '${account.id}': `, data);
    }

    return documentRef;
  };

  const updateCurrentAccount = async (data) => {
    const documentRef = await getAccountDocumentById(account.id);

    if (typeof documentRef?.update === `function`) {
      await documentRef.update(data);

      setAccount({
        ...account,
        ...data
      });

      sendNotification(`Account info updated.`);
    } else {
      // eslint-disable-next-line no-console
      console.error(`Cannot update data for ID '${account.id}': `, data);
    }

    return documentRef;
  };

  //
  // stripe

  const subscribe = async ({
    accountId,
    onComplete,
    paymentMethodId,
    trial
  }) => {
    const createStripeSubscription = firebase
      .functions()
      .httpsCallable(`createStripeSubscription`);

    const stripeSubscription = await createStripeSubscription({
      accountId,
      planId: `premium`,
      paymentMethodId,
      trial
    });

    sendNotification(`Subscription created.`);
    onComplete(stripeSubscription);
  };

  const confirmSubscribe = async ({ paymentMethodId, subscription }) => {
    if (!account?.id) {
      return;
    }

    const confirmSubscription = firebase
      .functions()
      .httpsCallable(`confirmSubscription`);

    const stripeAccount = await confirmSubscription({
      accountId: account.id,
      billing: {
        country: `United Kingdom`,
        state: ``
      },
      planId: `premium`,
      paymentMethodId,
      subscription
    });

    console.log(`[debug] stripeAccount: `, stripeAccount);
  };

  const purchase = ({ cost, tokenKey }) =>
    new Promise((resolve, reject) => {
      if (!loggedIn) {
        reject(new Error(`Unauthenticated`));
        return;
      }

      let { purchasedArticles, tokens } = account;

      const purchasedArticlesArray = purchasedArticles?.split(`,`);

      tokens = parseInt(tokens);

      if (purchasedArticlesArray?.includes(tokenKey.toLowerCase())) {
        reject(new Error(`Article already purchased`));
        return;
      }

      if (cost > tokens) {
        reject(new Error(`Insufficient tokens`));
        return;
      }

      purchasedArticles += `${
        purchasedArticlesArray?.[0] ? `,` : ``
      }${tokenKey.toLowerCase()}`;
      tokens -= cost;

      updateCurrentAccount({
        purchasedArticles,
        tokens
      });

      sendNotification(`Purchase complete (${cost} Tokens).`);

      resolve(account);
    });

  const rsvp = ({ cost, tokenKey }) =>
    new Promise((resolve, reject) => {
      if (!loggedIn) {
        reject(new Error(`Unauthenticated`));
        return;
      }

      let { purchasedEvents, tokens } = account;

      const purchasedEventsArray = purchasedEvents?.split(`,`);

      tokens = parseInt(tokens);

      if (purchasedEventsArray?.includes(tokenKey.toLowerCase())) {
        reject(new Error(`Event already purchased`));
        return;
      }

      if (cost > tokens) {
        reject(new Error(`Insufficient tokens`));
        return;
      }

      purchasedEvents += `${
        purchasedEventsArray?.[0] ? `,` : ``
      }${tokenKey.toLowerCase()}`;
      tokens -= cost;

      updateCurrentAccount({
        purchasedEvents,
        tokens
      });

      sendNotification(`Purchase complete (${cost} Tokens).`);

      resolve(account);
    });

  const stripeCheckout = async (price) => {
    const { stripeCustomerId } = account;

    if (!stripeCustomerId) {
      return null;
    }

    const createCheckout = firebase.functions().httpsCallable(`createCheckout`);

    const line_items = [
      {
        price,
        quantity: 1
        // tax_rates
      }
    ];

    const response = await createCheckout({
      customer: stripeCustomerId,
      line_items
    });

    return response;
  };

  const getInvoices = async () => {
    const { stripeCustomerId } = account;

    const getInvoicesByCustomerId = firebase
      .functions()
      .httpsCallable(`getInvoicesByCustomerId`);

    const response = await getInvoicesByCustomerId({ stripeCustomerId });

    return response?.data?.invoices || null;
  };

  const getPaymentMethod = async () => {
    const { stripeCustomerId } = account;

    const getStripePaymentInfo = firebase
      .functions()
      .httpsCallable(`getStripePaymentInfo`);

    const response = await getStripePaymentInfo({ stripeCustomerId });

    return response?.data || null;
  };

  // ==========================================================================
  // lifecycle

  useEffect(async () => {
    if (BROWSER && window.location.href.includes(`localhost`)) {
      firebase.functions().useEmulator(`localhost`, 5001);
    }

    firebase.auth().onAuthStateChanged((user) => {
      if (user !== null) {
        user.getIdToken().then((token) => {
          setAuthUser(user);
          setLoggedIn(true);
        });
      } else {
        setInitialized(true);
      }
    });
  }, []);

  useEffect(() => {
    if (!BROWSER || account?.firstName || !authUser?.uid) {
      return;
    }

    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

    firebase
      .firestore()
      .collection(`accounts`)
      .where(`uid`, `==`, authUser.uid)
      .get()
      .then((res) => {
        if (res?.docs?.[0]) {
          const userDoc = res.docs[0];

          setInitialized(true);

          setAccount({
            id: userDoc.id,
            ...userDoc.data()
          });
        }
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
        setInitialized(true);
      });
  }, [authUser]);

  // ==========================================================================
  // render

  return (
    <AuthContext.Provider
      value={{
        account,
        authUser,
        setAuthUser,
        initialized,
        loggedIn,
        login,
        logout,
        resetUserPassword,
        resetEmailPassword,
        getAccountDocumentById,
        getInvoices,
        getPaymentMethod,
        loadAccountById,
        refreshCurrentAccount,
        purchase,
        rsvp,
        stripeCheckout,
        subscribe,
        updateAccountById,
        updateCurrentAccount,
        confirmSubscribe
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
