/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { createContext } from "react";
import firebase from "gatsby-plugin-firebase";

export const EmailContext = createContext({});

const EmailProvider = ({ children }) => {
  // ==========================================================================
  // methods

  const mail = async ({ to, templateID, replacements, subject }) => {
    const sendEmail = firebase.functions().httpsCallable(`sendEmail`);

    const response = await sendEmail({ to, templateID, replacements, subject });

    return response?.data || null;
  };

  const klaviyoSubscribe = async ({ listId, email }) => {
    const addEmailToKlaviyo = firebase
      .functions()
      .httpsCallable(`addEmailToKlaviyo`);

    return await addEmailToKlaviyo({
      email,
      listId
    });
  };

  // ==========================================================================
  // render

  return (
    <EmailContext.Provider
      value={{
        //
        mail,
        klaviyoSubscribe
      }}
    >
      {children}
    </EmailContext.Provider>
  );
};

export default EmailProvider;
