/* eslint-disable import/prefer-default-export */

import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import React from "react";
import PropTypes from "prop-types";
import AppProvider from "~context/AppContext/index.jsx";
import AuthProvider from "~context/AuthContext/index.jsx";
import EmailProvider from "~context/EmailContext/index.jsx";
import ThemeProvider from "~context/ThemeContext/index.jsx";

import "./src/assets/css/tailwind.build.css";
import "swiper/css";

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK);

const RootElement = ({ element }) => (
  <>
    <Elements stripe={stripePromise}>
      <AuthProvider>
        <EmailProvider>
          <AppProvider>
            <ThemeProvider>{element}</ThemeProvider>
          </AppProvider>
        </EmailProvider>
      </AuthProvider>
    </Elements>
  </>
);

RootElement.propTypes = {
  element: PropTypes.node.isRequired
};

export const wrapRootElement = RootElement;
