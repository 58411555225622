/* eslint-disable camelcase */
import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getColor } from "~utils/helpers";

export const ThemeContext = createContext({});

const ThemeProvider = ({ children }) => {
  // ===========================================================================
  // context / ref / state

  const [lowImpactMode, setLowImpactMode] = useState(false);
  const [colours, setColours] = useState({
    background: getColor(`green-dark`),
    foreground: getColor(`green-light`)
  });
  const [colourOverrides, setColourOverrides] = useState(null);

  // ===========================================================================
  // variables

  const colourTransitionCSS = `
    transition: 0.5s ease background, 0.5s ease color, 0.5s ease border
  `;

  // ===========================================================================
  // lifecycle

  useEffect(() => {
    if (lowImpactMode) {
      setColours({
        background: getColor(`wheat`),
        foreground: getColor(`charcoal`)
      });
    } else {
      setColours({
        background: colourOverrides?.background || getColor(`green-dark`),
        foreground: colourOverrides?.foreground || getColor(`green-light`)
      });
    }
  }, [colourOverrides, lowImpactMode]);

  // ===========================================================================
  // render

  return (
    <ThemeContext.Provider
      value={{
        colours,
        colourTransitionCSS,
        colourOverrides,
        setColourOverrides,
        lowImpactMode,
        setLowImpactMode
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ThemeProvider;
